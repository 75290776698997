import { Component, computed, inject, input, InputSignal } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import {
  MatListItem,
  MatListItemIcon,
  MatListItemMeta,
  MatListItemTitle,
  MatNavList,
} from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AuthState } from "../../../../../core/stores/auth.store";

@Component({
  selector: "app-side-nav",
  standalone: true,
  imports: [
    MatNavList,
    MatListItem,
    RouterLink,
    RouterLinkActive,
    MatIcon,
    MatListItemIcon,
    MatListItemTitle,
    MatSidenavModule,
    MatListItemMeta,
  ],
  templateUrl: "./side-nav.component.html",
  styleUrl: "./side-nav.component.scss",
})
export class SideNavComponent {
  authState = inject(AuthState);
  navItems = [
    {
      title: "Startsida",
      icon: "home",
      link: "kurser",
    },
    {
      title: "Kalender",
      icon: "calendar_month",
      link: "kalender",
    },
    {
      title: "Uppgifter",
      icon: "assignment",
      link: "uppgifter",
    },
    {
      title: "Verktyg",
      icon: "construction",
      link: "verktyg",
    },
  ];
}
