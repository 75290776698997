<mat-toolbar class="flex justify-between sticky top-0 z-10 border-b">
  <div class="flex items-center">
    @if((isHandset$ | async)) {
    <button
      (click)="toggleHandsetCollapsed()"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
    >
      @if(isHandsetOpened()) {
      <mat-icon aria-label="Side nav toggle icon">menu_open</mat-icon>
      } @else {
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      }
    </button>
    } @else {
    <button
      (click)="toggleCollapsed()"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
    >
      @if(!collapsed()) {
      <mat-icon aria-label="Side nav toggle icon">menu_open</mat-icon>
      } @else {
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      }
    </button>
    }

    <span class="ml-3">Musikläraren</span>
  </div>
  <div class="flex flex-row space-x-2 items-center">
    <button
      matTooltip="Lägg till ny kurs"
      mat-icon-button
      class="icon-button-large"
      (click)="openAddCourseOverlay()"
    >
      <mat-icon style="translate: scale(1.5)">add</mat-icon>
    </button>
    <img
      (click)="openUserInfoOberlay()"
      #userInfoMenu
      class="rounded-full h-10 w-10 cursor-pointer"
      [src]="authState.user()?.photoURL"
      alt="Profile picture"
    />
  </div>
</mat-toolbar>
<mat-sidenav-container
  autosize
  [ngClass]="
    (isHandset$ | async) ? 'h-[calc(100vh-56px)]' : 'h-[calc(100vh-64px)]'
  "
>
  <mat-sidenav
    (closedStart)="isHandsetOpened() ? toggleHandsetCollapsed() : ''"
    class="border-r pr-4"
    [style.width]="'240px'"
    [opened]="!(isHandset$ | async) ? !collapsed() : isHandsetOpened()"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
  >
    <app-side-nav />
  </mat-sidenav>
  <mat-sidenav-content class="bg-gray-200">
    <main class="p-2 md:p-8 h-full">
      <router-outlet />
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="flex flex-row w-screen pl-0"></div>
