<div class="flex flex-col h-screen items-center justify-center">
  <div class="flex flex-row">
    <button
      mat-raised-button
      [class.spinner]="auth.isLoading()"
      [disabled]="auth.isLoading()"
      (click)="auth.signInWithGoogle()"
    >
      Login with Google
    </button>
    @if (auth.user()) {
    <button
      mat-raised-button
      [disabled]="auth.isLoading()"
      (click)="auth.signOut()"
    >
      Logga ut
    </button>
    }
  </div>
  <div class="flex flex-col p-2 text-center">
    <p>Loading: {{ auth.isLoading() }}</p>
    <p>User: {{ auth.user()?.displayName }}</p>
  </div>
</div>
