import { Component, inject } from "@angular/core";
import { AuthState } from "./core/stores/auth.store";
import { Router, RouterOutlet } from "@angular/router";
import { Courses } from "./core/stores/courses.store";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet></router-outlet>`,
  styles: [],
})
export class AppComponent {
  auth = inject(AuthState);
  courses = inject(Courses);
  router = inject(Router);
  ngOnInit() {
    this.auth.readFromStorage();
    this.courses.readFromStorage();
  }
}
