<div class="h-full">
  <mat-nav-list>
    @for (item of navItems; track $index) {
    <mat-list-item
      routerLink="{{ item.link }}"
      routerLinkActive="active"
      #rla="routerLinkActive"
    >
      <mat-icon [fontSet]="'material-icons-outlined'" matListItemIcon>{{
        item.icon
      }}</mat-icon>
      <h3 matListItemTitle>{{ item.title }}</h3>
    </mat-list-item>
    } @if(authState.admin()) {
    <mat-list-item
      routerLink="skapa-uppgift"
      routerLinkActive="active"
      #rla="routerLinkActive"
    >
      <mat-icon [fontSet]="'material-icons-outlined'" matListItemIcon
        >add</mat-icon
      >
      <h3 matListItemTitle>Skapa uppgift</h3>
    </mat-list-item>
    }
  </mat-nav-list>
</div>
