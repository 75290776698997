import { inject, Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthState } from "../stores/auth.store";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class TeacherAuthGuard implements CanActivate {
  authState = inject(AuthState);
  router = inject(Router);
  authService = inject(AuthService);

  async getClaim(): Promise<string | null> {
    return new Promise((resolve) => {
      this.authService.auth.onAuthStateChanged(async (user) => {
        if (user) {
          const tokenResponse = await user.getIdTokenResult(true);
          resolve(tokenResponse.claims["role"] as string | null);
        } else {
          resolve(null);
        }
      });
    });
  }
  async canActivate(): Promise<boolean> {
    const role = await this.getClaim();
    if (role === "teacher") {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
