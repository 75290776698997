<div
  class="flex flex-col h-screen w-screen items-center justify-center bg-white text-pink-300"
>
  <div class="text-center bg-red">
    <div class="text-9xl mb-4">404</div>
    <p class="text-xl md:text-4xl">Har du tappat bort dig?</p>
    <button class="mt-6" mat-raised-button routerLink="/">
      Till startsidan
    </button>
  </div>
</div>
