<div class="flex justify-center items-center h-[60px] p-6">
  @if (message()) {
  <p class="items-center text-center p-6 bg-slate-200">{{ message() }}</p>
  }
</div>
<div class="flex flex-col h-[calc(100vh-60px)] items-center justify-center">
  <mat-form-field>
    <mat-label>Set key</mat-label>
    <input matInput type="text" #keyInput />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Set role</mat-label>
    <input matInput type="text" #roleInput />
  </mat-form-field>
  <button
    mat-button
    (click)="setRole(keyInput.value, roleInput.value)"
    [disabled]="auth.isLoading()"
  >
    Set Role
  </button>
</div>
