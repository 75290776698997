<div class="bg-white p-4 rounded-lg m-4">
  <h1 class="mb-4">Skapa kurs</h1>
  <form [formGroup]="courseForm">
    <mat-form-field class="w-full">
      <mat-label>Kursnamn (obligatorisk)</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Avsnitt</mat-label>
      <input matInput formControlName="chapter" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Årskurs</mat-label>
      <mat-select formControlName="class">
        @for (_ of timesNine; track $index) {
        <mat-option [value]="$index + 1">Årskurs {{ $index + 1 }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="flex flex-row justify-end space-x-2">
      <button mat-button (click)="closeOverlay()">Avbryt</button>
      <button
        mat-button
        [disabled]="courseForm.invalid"
        (click)="createCourse()"
      >
        Skapa kurs
      </button>
    </div>
  </form>
</div>
