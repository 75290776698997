import { Component, inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { AuthState } from "../../../core/stores/auth.store";
import { Router } from "@angular/router";

@Component({
  selector: "app-teacher-landing",
  standalone: true,
  imports: [MatButton],
  templateUrl: "./teacher-landing.component.html",
  styleUrl: "./teacher-landing.component.scss",
})
export class TeacherLandingComponent {
  auth = inject(AuthState);
  router = inject(Router);
  constructor() {
    if (this.auth.role() === "teacher") {
      this.router.navigate(["/larare"]);
    }
  }

  signOut() {
    this.auth.signOut();
  }
}
