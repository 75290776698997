import {
  Component,
  computed,
  ElementRef,
  inject,
  Signal,
  signal,
  viewChild,
  WritableSignal,
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AsyncPipe, NgClass } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { AuthState } from "../../../core/stores/auth.store";
import { SideNavComponent } from "./components/side-nav/side-nav.component";
import { Courses } from "../../../core/stores/courses.store";
import { ConnectedPosition, Overlay } from "@angular/cdk/overlay";
import { AddCourseComponent } from "./components/add-course/add-course.component";
import { ComponentPortal } from "@angular/cdk/portal";
import { UserInfoPanelComponent } from "./components/user-info-panel/user-info-panel.component";
import { MatTooltip } from "@angular/material/tooltip";
@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrl: "./layout.component.scss",
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    SideNavComponent,
    NgClass,
    MatTooltip,
  ],
})
export class LayoutComponent {
  private breakpointObserver = inject(BreakpointObserver);
  authState = inject(AuthState);
  coursesState = inject(Courses);
  overlay = inject(Overlay);
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  collapsed: WritableSignal<boolean> = signal(false);
  isHandsetOpened = signal(false);
  userInfoTrigger: Signal<ElementRef> = viewChild.required("userInfoMenu");
  toggleCollapsed() {
    this.collapsed.update((collapsed) => !collapsed);
  }

  toggleHandsetCollapsed() {
    this.isHandsetOpened.update((opened) => !opened);
  }
  openAddCourseOverlay() {
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
    });
    const addCoursePortal = new ComponentPortal(AddCourseComponent);
    const ref = overlayRef.attach(addCoursePortal);

    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
    ref.instance.close.subscribe(() => overlayRef.dispose());
  }

  openUserInfoOberlay() {
    const positions: ConnectedPosition[] = [
      {
        originX: "end",
        originY: "center",
        overlayX: "start",
        overlayY: "center",
        offsetX: -16,
        offsetY: 65,
      },
    ];
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.userInfoTrigger().nativeElement)
        .withPositions(positions),
      hasBackdrop: false,
    });
    const addCoursePortal = new ComponentPortal(UserInfoPanelComponent);
    const ref = overlayRef.attach(addCoursePortal);
    overlayRef.outsidePointerEvents().subscribe(() => overlayRef.dispose());
    overlayRef.keydownEvents().subscribe((key) => {
      if (key.code === "Escape") {
        overlayRef.dispose();
      }
    });

    ref.instance.close.subscribe(() => overlayRef.dispose());
  }
}
