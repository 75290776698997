import { Routes } from "@angular/router";
import { LayoutComponent } from "./private/teacher/layout/layout.component";
import { TeacherLandingComponent } from "./public/pages/teacher-landing/teacher-landing.component";
import { SetRoleComponent } from "./debug/set-role/set-role.component";
import { TeacherAuthGuard } from "./core/guards/is-teacher.guard";
import { AdminAuthGuard } from "./core/guards/is-admin.guard";
import { PageNotFoundComponent } from "./public/pages/page-not-found/page-not-found.component";

export const routes: Routes = [
  {
    path: "",
    component: TeacherLandingComponent,
    pathMatch: "full",
  },
  {
    path: "larare",
    component: LayoutComponent,
    canActivate: [TeacherAuthGuard],
    loadChildren: () =>
      import("./private/teacher/teacher.routes").then((m) => m.routes),
  },

  //IMPORTANT, REMOVE ON PROD BUILD
  {
    path: "set-role",
    component: SetRoleComponent,
    //canActivate: [AdminAuthGuard],
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];
