import { Component, inject, signal } from "@angular/core";
import { AuthState } from "../../core/stores/auth.store";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatButton } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
@Component({
  selector: "app-set-role",
  standalone: true,
  imports: [MatButton, MatInputModule],
  templateUrl: "./set-role.component.html",
  styleUrl: "./set-role.component.scss",
})
export class SetRoleComponent {
  auth = inject(AuthState);
  http = inject(HttpClient);
  message = signal("");
  setRole(key: string, role: string) {
    if (!this.auth.user()) {
      return;
    }

    let headers: HttpHeaders;

    this.auth
      .getUserToken()
      .then((res) => {
        console.log("Token", res);
        if (!res) return;
        const authHeaders = {
          "Content-Type": "application/json",
          Authorization: res,
        };
        headers = new HttpHeaders(authHeaders);
      })
      .catch((error: Error) => {
        console.log("Could not get token", error);
        throw error;
      })
      .finally(() => {
        if (!headers) {
          console.log("Headers not defined");
          return;
        }
        const url = `https://188.66.62.167:3100/api/update/${
          this.auth.user()?.uid
        }`;
        const claims = { [key]: role };
        this.http.put(url, claims, { headers: headers }).subscribe({
          next: (response: any) => {
            this.message.set(
              key + ":" + role + " Response:" + response.message
            );
            console.log("Role updated successfully:", response);
          },
          error: (error) => {
            this.message.set(error.message);
            console.error("Error updating role: ", error);
          },
        });
      });
  }
}
