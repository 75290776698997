import { Component, inject, output } from "@angular/core";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { Courses } from "../../../../../core/stores/courses.store";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOption } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatButton } from "@angular/material/button";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-course",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatOption,
    MatSelectModule,
    MatButton,
  ],
  templateUrl: "./add-course.component.html",
  styleUrl: "./add-course.component.scss",
})
export class AddCourseComponent {
  coursesState = inject(Courses);
  fb = inject(FormBuilder);
  router = inject(Router);
  close = output();
  timesNine = Array(9)
    .fill(0)
    .map((_, i) => i);

  courseForm = this.fb.nonNullable.group({
    name: ["", Validators.required],
    class: "",
    chapter: "",
  });

  createCourse() {
    this.courseForm.disable();
    this.coursesState
      .addCourse(this.courseForm.value)
      .then(() => {
        this.courseForm.reset();
        this.closeOverlay();
      })
      .catch(() => {
        this.courseForm.enable();
      });
  }
  closeOverlay() {
    this.close.emit();
  }
}
