import { Component, inject, output } from "@angular/core";
import { AuthState } from "../../../../../core/stores/auth.store";
import { MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-user-info-panel",
  standalone: true,
  imports: [MatIconButton, MatIcon, MatButtonToggleModule, RouterLink],
  templateUrl: "./user-info-panel.component.html",
  styleUrl: "./user-info-panel.component.scss",
})
export class UserInfoPanelComponent {
  authState = inject(AuthState);
  close = output();

  closeOverlay() {
    this.close.emit();
  }
  signOut() {
    this.closeOverlay();
    this.authState.signOut();
  }
}
