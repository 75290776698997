<div
  class="flex items-center flex-col bg-white rounded-3xl mat-elevation-z4 py-4 px-4 w-[339px] border border-gray-300"
>
  <button (click)="closeOverlay()" class="absolute top-0 right-0 p-4">
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center flex items-center mb-8">
    <div class="w-full text-sm">
      <p class="font-semibold">{{ authState.user()?.email }}</p>
      <p class="text-gray-500">
        @if(authState.role() === 'teacher') { Lärare } @if (authState.admin()) {
        / Administratör }
      </p>
    </div>
  </div>
  <img [src]="authState.user()?.photoURL" class="w-32 h-32 rounded-full" />
  <p class="text-xl mt-4 text-center">
    Hej {{ authState.user()?.displayName }}!
  </p>

  <mat-button-toggle-group
    name="meny"
    [hideSingleSelectionIndicator]="true"
    class="mt-8 mb-6"
  >
    <mat-button-toggle
      value="settings"
      routerLink="larare/installningar"
      (click)="closeOverlay()"
      ><mat-icon class="mr-2" [fontSet]="'material-icons-outlined'"
        >settings</mat-icon
      >Inställningar</mat-button-toggle
    >
    <mat-button-toggle value="logout" (click)="signOut()"
      ><mat-icon class="mr-2">power_settings_new</mat-icon>Logga
      ut</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
